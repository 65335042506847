import { Pause, Play } from '@aether/icons'
import { styled } from '@aether/styles'
import { MouseEventHandler, FC } from 'react'

type VideoControlButtonProps = {
  isPlaying: boolean
  videoTitle?: string
  onRequestPause: MouseEventHandler<HTMLButtonElement>
  onRequestPlay: MouseEventHandler<HTMLButtonElement>
}
const ControlButton = styled('button', {
  padding: 0,
  margin: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  filter: 'drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.8))',
  width: '$32',
  height: '$32',
  background: 'transparent',
  zIndex: '$videoControls',
  borderRadius: '$rMax',
  pointerEvents: 'all',

  '&:hover': {
    cursor: 'pointer',
  },

  $focusStyle: 'default',
})

export const VideoControlButton: FC<VideoControlButtonProps> = ({
  isPlaying,
  videoTitle,
  onRequestPause,
  onRequestPlay,
}) => {
  const ariaLabel = (action: string) => {
    return videoTitle ? `${action} ${videoTitle}` : action
  }

  return isPlaying ? (
    <ControlButton
      onClick={onRequestPause}
      aria-label={ariaLabel('Pause video')}
    >
      <Pause />
    </ControlButton>
  ) : (
    <ControlButton onClick={onRequestPlay} aria-label={ariaLabel('Play video')}>
      <Play />
    </ControlButton>
  )
}
