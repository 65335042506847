import { styled } from '@aether/styles'
import { FC } from 'react'

const StyledSwitch = styled('div', {
  display: 'inline-grid',
  gridAutoFlow: 'column',
  borderRadius: '$r1',
  padding: '3px',

  variants: {
    type: {
      fill: {
        border: '1px solid $black',
        gap: '2px',
      },
      outline: {
        gap: '$8',
      },
    },
  },
})

const StyledRadioButton = styled('div', {
  position: 'relative',
  $aetherFont: 'ui06',
  borderRadius: '$r1',
  transition: 'background-color 0.3s, color 0.3s',

  variants: {
    isChecked: {
      true: {
        color: '$white',
      },
      false: {},
    },
    type: {
      fill: {
        padding: '$8 18px',
      },
      outline: {
        padding: '$8',
      },
    },
  },

  compoundVariants: [
    {
      isChecked: true,
      type: 'fill',
      css: {
        border: 'none',
        backgroundColor: '$black',
      },
    },
    {
      isChecked: false,
      type: 'fill',
      css: {
        border: 'none',
        backgroundColor: 'transparent',
      },
    },
    {
      isChecked: true,
      type: 'outline',
      css: {
        color: '$black',
      },
    },
    {
      isChecked: false,
      type: 'outline',
      css: {
        color: '$gray_medium',
      },
    },
  ],
})

const StyledRadioInput = styled('input', {
  display: 'block',
  margin: 0,
  position: 'absolute',
  inset: 0,
  opacity: '0',
  zIndex: '100',
  cursor: 'pointer',
})

const StyledRadioLabel = styled('label', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  position: 'absolute',
  inset: 0,
  zIndex: '90',
})

type Props = {
  items: { value: string; label: string }[]
  value: string
  onChange: (newValue: string) => void
  type?: 'fill' | 'outline'
  name: string
}

export const RadioSwitch: FC<Props> = ({
  items,
  value,
  onChange,
  type = 'fill',
  name,
}) => {
  return (
    <StyledSwitch role="radiogroup" aria-label="Measurement units" type={type}>
      {items.map(({ label, value: itemValue }) => {
        const radioId = `radio-${name}-${itemValue}`
        return (
          <StyledRadioButton
            key={itemValue}
            isChecked={value === itemValue}
            type={type}
          >
            <StyledRadioInput
              type="radio"
              id={radioId}
              name={name}
              value={itemValue}
              checked={value === itemValue}
              aria-checked={value === itemValue}
              onChange={() => {
                onChange(itemValue)
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onChange(itemValue)
                }
              }}
            />
            <StyledRadioLabel htmlFor={radioId}>{label}</StyledRadioLabel>
          </StyledRadioButton>
        )
      })}
    </StyledSwitch>
  )
}
