import { FC } from 'react'
import { styled } from '@aether/styles'
import { useTranslation } from 'react-i18next'
import { ShopifyPrice } from '@aether/models'
import { getI18nPrice } from '@aether/utils'
import { useRouter } from 'next/router'
import { CartTotalPrice, PricePrimitive } from '@aether/components'
import { ShopifyResolvedCart } from '@aether/shopify-sdk'

const SubTotalWrap = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  $aetherFont: 'ui01',
  paddingBottom: '$8',
})

const InsiderMemberSavingsWrap = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  $aetherFont: 'ui01',
  paddingBottom: '$20',
  color: '$blue',
})

const SubTotalText = styled('span', {
  $aetherFont: 'ui01',
})

const AfterPayText = styled('span', {
  $aetherFont: 'ui03',
})

const getAfterPayAmount = (price?: ShopifyPrice, locale?: string) => {
  if (!price) {
    return ''
  }

  const { amount, currencyCode } = price

  const paymentAmount = Number(amount) / 4

  const paymentAmountPrice: ShopifyPrice = {
    amount: String(paymentAmount),
    currencyCode,
  }

  return getI18nPrice(paymentAmountPrice, locale)
}

export const SubTotalSummary: FC<{
  cart: ShopifyResolvedCart | null
  isVip?: boolean
  insiderMemberSavings?: number
  className?: string
}> = ({ cart, isVip = false, insiderMemberSavings, className }) => {
  const { t } = useTranslation('cart')
  const { locale } = useRouter()

  const price = cart?.cost?.subtotalAmount
  const currency = cart?.cost?.subtotalAmount.currencyCode

  const afterPayAmount = getAfterPayAmount(price, locale)

  return (
    <div className={className}>
      {isVip && (
        <InsiderMemberSavingsWrap>
          <SubTotalText>{t('insiderMemberSavings')}</SubTotalText>
          <PricePrimitive
            price={{
              amount: insiderMemberSavings?.toString() ?? '',
              currencyCode: currency ?? '',
            }}
          />
        </InsiderMemberSavingsWrap>
      )}
      <SubTotalWrap>
        <SubTotalText>{t('subtotal')}</SubTotalText>
        <CartTotalPrice cart={cart} isVip={isVip} />
      </SubTotalWrap>

      {!isVip && (
        <AfterPayText>{t('afterpay', { value: afterPayAmount })}</AfterPayText>
      )}
    </div>
  )
}
