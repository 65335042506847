import {
  forwardRef,
  useEffect,
  useRef,
  useState,
  MouseEventHandler,
} from 'react'
import {
  MediaLayoutType,
  VideoControlsPositionType,
} from '../models/MediaProps'
import { MuxImageMediaPayload } from '@aether/models'
import { VideoControlButton } from './VideoControlButton'
import { styled } from '@aether/styles'
import { MuxImage } from './MuxImage'
import { MediaSizesProps } from '../helpers/buildSizes'
import { MediaRatiosTypeExtended, ratios } from '../ratios'
import { VideoAudioButton } from './VideoAudioButton'

const Container = styled('div', {
  position: 'relative',
  variants: {
    layout: {
      fill: {
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        position: 'absolute',
      },
      responsive: {},
      intrinsic: {},
      'responsive-cover': {},
    },
  },
})

const VideoElement = styled('video', {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  top: 0,
  left: 0,
})

const StyledControls = styled('div', {
  position: 'absolute',
  right: '$16',

  display: 'grid',
  gridAutoFlow: 'column',
  gap: '$8',

  zIndex: '$videoControls',

  variants: {
    controlsPosition: {
      'bottom-right': {
        bottom: '$16',
        right: '$16',
      },
      none: {
        display: 'none',
      },
    },
  },
})

type Props = {
  layout?: MediaLayoutType
  width: number
  height: number
  hardcropRatio?: MediaRatiosTypeExtended
  firstFrame: string
  priority?: boolean
  alt?: string
  autoplay?: boolean
  muted?: boolean
  loop?: boolean
  src?: string
  play: boolean
  isPlaying: boolean
  sizes?: MediaSizesProps
  controlsPosition?: VideoControlsPositionType
  onRequestPause: MouseEventHandler<HTMLButtonElement>
  onRequestPlay: MouseEventHandler<HTMLButtonElement>
  onEnded: () => void
  onPlaying?: () => void
}

export const Video = forwardRef<HTMLDivElement, Props>(
  (
    {
      layout,
      width,
      height,
      sizes,
      firstFrame,
      priority,
      alt,
      autoplay = true,
      loop = true,
      muted = true,
      src,
      play,
      isPlaying,
      controlsPosition,
      onRequestPause,
      onRequestPlay,
      onPlaying,
      onEnded,
      hardcropRatio,
    },
    ref,
  ) => {
    const containerStyles = (() => {
      if (layout !== 'responsive') return undefined

      if (hardcropRatio && typeof hardcropRatio === 'string') {
        const ratioValue = ratios[hardcropRatio]
        const paddingBottom = `${ratioValue * 100}%`
        return { paddingBottom }
      }

      const originalRatio = `${(height / width) * 100}%`
      return { paddingBottom: originalRatio }
    })()
    const videoRef = useRef<HTMLVideoElement>(null)

    const [loaded, setLoaded] = useState(false)
    const [isAudioEnabled, setIsAudioEnabled] = useState(false)
    const [isMuted, setIsMuted] = useState(muted)

    const firstFramePayload: MuxImageMediaPayload = {
      type: 'mux-image',
      image: {
        src: firstFrame,
      },
    }

    const onRequestUnmute: MouseEventHandler<HTMLButtonElement> = (event) => {
      event.preventDefault()
      setIsMuted(false)
    }

    const onRequestMute: MouseEventHandler<HTMLButtonElement> = (event) => {
      event.preventDefault()
      setIsMuted(true)
    }

    const hasAudio = (video: any) => {
      return (
        video.mozHasAudio ||
        Boolean(video.webkitAudioDecodedByteCount) ||
        Boolean(video.audioTracks?.length)
      )
    }

    const videoEl = videoRef.current

    useEffect(() => {
      if (videoEl && play) {
        videoEl?.play()

        return
      }

      if (videoEl && !play) {
        videoEl?.pause()

        return
      }
    }, [play, videoEl])

    useEffect(() => {
      if (videoEl && hasAudio(videoEl)) {
        setIsAudioEnabled(true)
      }
    }, [loaded])

    return (
      <Container layout={layout} style={containerStyles} ref={ref}>
        <MuxImage
          mediaPayload={firstFramePayload}
          priority={priority}
          layout={'fill'}
          objectFit={'cover'}
          sizes={sizes}
        />
        <VideoElement
          ref={videoRef}
          autoPlay={autoplay}
          muted={isMuted}
          loop={loop}
          playsInline
          title={alt}
          src={src}
          onEnded={onEnded}
          onPlaying={onPlaying}
          onCanPlay={() => setLoaded(true)}
        />
        <StyledControls controlsPosition={controlsPosition}>
          {loaded && (
            <VideoAudioButton
              isMuted={isMuted}
              videoTitle={alt}
              onRequestMute={onRequestMute}
              onRequestUnmute={onRequestUnmute}
              isDisabled={!isAudioEnabled}
            />
          )}
          {loaded && (
            <VideoControlButton
              isPlaying={isPlaying}
              videoTitle={alt}
              onRequestPause={onRequestPause}
              onRequestPlay={onRequestPlay}
            />
          )}
        </StyledControls>
      </Container>
    )
  },
)
