import { LocaleId } from '@aether/configs'
import { groq } from 'next-sanity'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { createMediaProjection } from '../projections/createMediaProjection'

export const createPasswordModuleProjection = (localeId: LocaleId) => groq`{
  ${coalesceLocaleField('title', localeId)},
  media${createMediaProjection(localeId)},
  "isPasswordProtected": password != '' && password != null,
}`
