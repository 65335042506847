import { getLocaleRegionIdFromPath } from '@aether/utils'
import { groq } from 'next-sanity'
import { getClient } from '../getClient'
import { PageResponse, PageBuilderSection } from '@aether/models'
import { createPageBuilderProjection } from '../projections/createPageBuilderProjection'
import { LocaleId } from '@aether/configs'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { createRichTextProjection } from '../projections/createRichTextProjection'
import { createIsHeaderStaticProjection } from '../projections/createIsHeaderStaticProjection'
import { createSectionTechnicalSpecificationsProjection } from '../sections/createSectionTechnicalSpecificationsProjection'
import { createModulesProjection } from '../projections/createModulesProjection'

export const createProductPageProjection = (localeId: LocaleId) => {
  const PAGE_BUILDER_PROJECTION = createPageBuilderProjection(localeId)
  const RICH_TEXT_PROJECTION = createRichTextProjection(localeId)
  const IS_HEADER_STATIC_PROJECTION = createIsHeaderStaticProjection()

  return groq`{
    "type": _type,
    _id,
    "hideHeader": false,
    "hideFooter": false,
    "productId": store.gid,
    ${IS_HEADER_STATIC_PROJECTION},
    "pageBuilder": [
      {
        "isTechnicalSpecificationPresent": count(pageBuilder[_type == "sectionTechnicalSpecifications"]) > 0,
        ${coalesceLocaleField(
          'additionalDescription',
          localeId,
        )}[]${RICH_TEXT_PROJECTION},
        "_type": "sectionProductConversionArea",
        "_key": "sectionProductConversionArea" + store.gid,
        "productId": store.gid,
        "shopifyIds": [
          {
            "id": store.gid,
            "type": "product-related"
          },
        ],
        "sectionTechnicalSpecification": pageBuilder[_type == "sectionTechnicalSpecifications"]{
          _type == 'sectionTechnicalSpecifications' =>  ${createSectionTechnicalSpecificationsProjection(
            localeId,
          )},
        }[0],
      },
      ...pageBuilder[]${PAGE_BUILDER_PROJECTION}
    ],
    ${createModulesProjection(localeId)}
  }`
}

export const getProductPageBySlug = async (
  slug: string,
  localeRegionString: string,
  preview = false,
): Promise<PageResponse<PageBuilderSection[]> | undefined> => {
  const [, localeId] = getLocaleRegionIdFromPath(localeRegionString)
  const PRODUCT_PROJECTION = createProductPageProjection(localeId)

  const query = (() => {
    if (preview) {
      return groq`*[ _type == "product" && defined(store.slug.current) && store.slug.current == "${slug}"][0]${PRODUCT_PROJECTION}`
    }

    return groq`*[!(_id in path('drafts.**')) && _type == "product" && defined(store.slug.current) && store.slug.current == "${slug}" && store.isDeleted == false && store.status == "active"][0]${PRODUCT_PROJECTION}`
  })()

  const pageData: PageResponse<PageBuilderSection[]> = await getClient(
    preview,
  ).fetch(query)

  return pageData
}
