import { FC } from 'react'
import { styled } from '@aether/styles'
import { ShopifyProduct } from '@aether/models'
import { Media } from '../Media/Media'
import { MediaSizesProps } from '../Media/helpers/buildSizes'
import { ProductPrice } from '../Price/ProductPrice/ProductPrice'
import { Link } from '../Link/Link'

import { ROUTE_BASE_PRODUCTS } from '@aether/configs'
import { useCustomerContext } from '@aether/account/utils-customer-context'
import { useDiscounts } from '@aether/discounts/utils-context'
import { formatProductName } from '@aether/utils'

type PostPreviewItemProps = {
  product: ShopifyProduct
  mediaSizes?: MediaSizesProps
  variant?: 'default' | 'popup'
}

const Wrap = styled('div', {
  display: 'grid',
})
const ContentWrap = styled('div', {
  gridColumn: 1,
  gridRow: 1,
  pointerEvents: 'none',
})
const LinkWrap = styled('div', {
  display: 'grid',
  gridColumn: 1,
  gridRow: 1,
})

const ImageWrap = styled('div', {
  marginBottom: '$12',
})

const ProductTitle = styled('p', {
  paddingBottom: '$4',

  variants: {
    variant: {
      default: {
        $aetherFont: 'heading05',
      },
      popup: {
        $aetherFont: 'heading06',
      },
    },
  },
})

const ProductPriceWrap = styled('span', {
  $aetherFont: 'ui03',
})

export const ProductCard: FC<PostPreviewItemProps> = ({
  product,
  product: { title, featuredImage, handle },
  mediaSizes,
  variant = 'default',
}) => {
  const { userRole } = useCustomerContext()
  const isVip = userRole === 'vip'
  const { matchedDiscount } = useDiscounts({
    collections: product.collections?.edges.map((edge) => edge.node.id),
    userRole,
    productId: product.id,
  })

  const trimmedTitle = formatProductName(title)

  return featuredImage ? (
    <Wrap>
      <ContentWrap>
        <ImageWrap>
          <Media
            mediaPayload={featuredImage}
            hardcropRatio={'portrait1'}
            sizes={mediaSizes}
            withBackground
          />
        </ImageWrap>
        <Link href={`${ROUTE_BASE_PRODUCTS}/${handle}`} appearance="block">
          <ProductTitle variant={variant}>{trimmedTitle}</ProductTitle>
        </Link>
        <ProductPriceWrap>
          <ProductPrice
            product={product}
            discount={isVip ? matchedDiscount : undefined}
            promoPriceColor={isVip ? 'blue' : undefined}
          />
        </ProductPriceWrap>
      </ContentWrap>
      <LinkWrap>
        <Link
          href={`${ROUTE_BASE_PRODUCTS}/${handle}`}
          tabIndex={-1}
          aria-hidden={true}
        />
      </LinkWrap>
    </Wrap>
  ) : null
}
