import { getLocaleRegionIdFromPath } from '@aether/utils'
import { groq } from 'next-sanity'
import { getClient } from '../getClient'
import { PageResponse } from '@aether/models'
import { PageBuilderSection } from '@aether/models'
import { createPageBuilderProjection } from '../projections/createPageBuilderProjection'
import { LocaleId } from '@aether/configs'
import { createMediaProjection } from '../projections/createMediaProjection'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { withCollectionDistributedProducts } from '../helpers/withCollectionDistributedProducts'
import { createCollectionNavigationProjection } from '../projections/createCollectionNavigationProjection'
import { createRichTextProjection } from '../projections/createRichTextProjection'
import { createSanityColorProjection } from '../projections/createSanityColorProjecton'
import { createModulesProjection } from '../projections/createModulesProjection'

export const createCollectionPageProjection = (
  localeId: LocaleId,
  collectionName: string,
) => {
  const PAGE_BUILDER_PROJECTION = createPageBuilderProjection(localeId)
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  const BLOCK_CONTENT_PROJECTION = createRichTextProjection(localeId)
  const SANITY_COLOR_PROJECTION = createSanityColorProjection()
  const COLLECTION_NAVIGATION_PROJECTION =
    createCollectionNavigationProjection(localeId)

  const SEO_PROJECTION = groq`{
    "ogImage": select(defined(background.image) => background${MEDIA_PROJECTION}, null),
    "ogAltImage": select(defined(background.alt) => {
      ${coalesceLocaleField('alt', localeId)},
    }.alt, null),
    "metaTitle": {${coalesceLocaleField('title', localeId)}}.title,

  }
 `

  return groq`{
    _id,
    "type":_type,
    "hideFooter": false,
    "hideHeader": false,
    "seo": ${SEO_PROJECTION},
    "isHeaderStatic": true,
    "pageBuilder": [
      {
        "_type": "sectionCollectionHero",
        "_key": "sectionCollectionHero",
        background${MEDIA_PROJECTION},
        theme,
        ${coalesceLocaleField('title', localeId)},
        isSplitHeader,
        splitHeaderTheme,
        ${coalesceLocaleField(
          'splitHeaderText',
          localeId,
        )}[]${BLOCK_CONTENT_PROJECTION},
        splitHeaderBackgroundColor${SANITY_COLOR_PROJECTION},
      },
      {
        "_type": "sectionCollectionFiltering",
        "_key": "sectionCollectionFiltering",
        "context":"collectionPage",
        "navigationLinks": navigationLinks -> ${COLLECTION_NAVIGATION_PROJECTION},
      },
      ...pageBuilder[]${PAGE_BUILDER_PROJECTION},
      {
        "_key": 'sectionCollectionItems-last',
        "_type": 'sectionCollectionItems',
        "isLastCollectionSection": true,
      },
      {
        "_type": "sectionCollectionAnalytics",
        "_key": "sectionCollectionAnalytics",
        "collectionName": "${collectionName}"
      },
    ],
    ${createModulesProjection(localeId)}
  }`
}

export const getCollectionPageBySlug = async (
  slug: string,
  locale: string,
  preview = false,
): Promise<
  Omit<PageResponse<PageBuilderSection[]>, 'siteConfiguration'> | undefined
> => {
  const [, localeId] = getLocaleRegionIdFromPath(locale)
  const CATEGORY_PROJECTION = createCollectionPageProjection(localeId, slug)

  const query = (() => {
    if (preview) {
      return groq`*[ _type == "collection" && store.slug.current == "${slug}"][0]${CATEGORY_PROJECTION}`
    }

    return groq` *[!(_id in path('drafts.**')) && _type == "collection" && store.slug.current == "${slug}"][0]${CATEGORY_PROJECTION}`
  })()

  const pageData = await getClient(preview).fetch(query)

  return withCollectionDistributedProducts(pageData)
}
