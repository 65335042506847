import { groq } from 'next-sanity'
import { LocaleId } from '@aether/configs'
import { createStickyCtaModuleProjection } from '../modules/stickyCtaModule'
import { createPasswordModuleProjection } from '../modules/passwordModule'

export const createModulesProjection = (localeId: LocaleId) => groq`
"passwordModule": modules[_type == "passwordModule"][0]${createPasswordModuleProjection(
  localeId,
)},
modules[_type != "passwordModule"]{
  _type == 'stickyCtaModule' =>  ${createStickyCtaModuleProjection(localeId)},
}`
