import { styled } from '@aether/styles'

export type PromoPriceWrapperProps = 'red' | 'white' | 'black' | 'blue'
export const PromoPriceWrapper = styled('span', {
  variants: {
    color: {
      red: {
        color: '$red',
      },
      white: {
        color: '$white',
      },
      black: {
        color: '$black',
      },
      blue: {
        color: '$blue',
      },
    },
  },
})
